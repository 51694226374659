import React, { useState, useEffect } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Link } from "gatsby"
// import { addSlashButtonLink } from '../../helper'
// import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
// import { navigate } from '@reach/router'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const MarketingLeadersData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'Report Revenue, Not Conversions',
               content:
                    'Bridge the gap between marketing analytics and your revenue reporting. ListenLayer allows your team to build robust reports across channels, so you can prove results. ',
          },
          {
               title: 'No More "Anonymous" Data',
               content:
                    "Identify real leads, prospects, and companies in your marketing and campaign analytics. Learn what's really working so your team can stop guessing.",
          },
          {
               title: 'Speed & Data Quality',
               content:
                    'Future-proof your data strategy! ListenLayer enables 100% first-party, cookieless tracking while improving the speed of your website. Implementation is easy and requires no programming.',
          },
          {
               title: 'Privacy Compliance, Out of the Box',
               content:
                    "Cookie laws are complicated. Centralize your compliance and reduce the number of tools you rely on with ListenLayer's built-in (and pre-configured) CMP - or integrate with the tools you already use. ",
          },
          {
               title: 'Trust Your Data',
               content:
                    'Take your data strategy in-house and stop the endless, revolving door of re-builds. ListenLayer allows your team to confidently implement and maintain a single, long-term strategy that you can trust!',
          },
          {
               title: 'Centralize Definitions',
               content:
                    'Centralize how you define conversions and KPIs used across your data destinations like Google Ads, GA4, LinkedIn, Facebook, Microsoft Ads, Twitter, and more...',
          },
          {
               title: 'Google Analytics Alternative',
               content:
                    "Finally! An alternative to Google Analytics that provides more features and allows you to own, and use your data the way your team needs. Stop serving Google's interests and start serving your own.",
          },
          {
               title: 'World Class Support',
               content:
                    'Fill gaps in your team by leaning on our world class support to ensure successful implementation. Be confident you have a partner that cares about your data needs.',
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     const handleShowSchedule = () => {
          setShowSchedule(true)
          if (window.location.href.includes('&')) {
               let newUrl = window.location.href + '&view-demo=1'
               window.history.replaceState(null, null, newUrl)
          } else {
               window.history.replaceState(null, null, '?view-demo=1')
          }
     }

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     const pageName = 'Marketing Leaders'
     const breadcrumbItems = [
          {
               name: 'Outcomes',
               url: `${'outcomes'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <SEO classBody="custom-centralized-marketing marketing-leaders" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <b className="platform-intro-des">Marketing Leaders</b>
                              <h1 className="mt-3">Tie Marketing Activity to Revenue</h1>
                              <b className="platform-intro-str">Without Dedicating Massive Resources to Manual Reporting</b>
                              <p className="platform-intro-title" style={{ width: '90%' }}>
                                   Your job is to prove (and improve) marketing's contribution to revenue and pipeline. ListenLayer bridges the gap
                                   between marketing analytics and revenue - automatically! The impossible just became possible.
                              </p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card custom-platform-intro">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6 mt-3">
                                                  <div className="card-step">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            {item.content && <p className="card-desc">{item.content}</p>}
                                                            {item.otherContentHtml && (
                                                                 <div
                                                                      className="custom-desc"
                                                                      dangerouslySetInnerHTML={{ __html: item.otherContentHtml }}
                                                                 ></div>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>

               <section style={{ marginBottom: '30px' }}>
                    <div className="section-ready mt-0">
                         <div className="container">
                              <div className=" section-ready-box">
                                   <div className="title">
                                        <h3>Schedule a Demo for Marketing Leaders</h3>
                                        <p>
                                             We'll show you how ListenLayer enables your team to drive better marketing outcomes <br /> (and helps you
                                             to prove contribution to revenue).
                                        </p>
                                   </div>
                                   <div className="button-wrapper">
                                        <button
                                             className="button"
                                             to={'#'}
                                             style={{ padding: '21px 28px', fontSize: '15px' }}
                                             onClick={() => handleShowSchedule()}
                                        >
                                             Schedule Demo
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default MarketingLeadersData
